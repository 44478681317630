var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "error-page" }, [
    _c(
      "div",
      { staticClass: "content-con" },
      [
        _c("img", {
          attrs: { src: require("_a/images/error-page/error-404.svg") }
        }),
        _vm._m(0),
        _c(
          "Button",
          {
            attrs: { size: "large", type: "text" },
            on: { click: _vm.backHome }
          },
          [_vm._v("返回首页")]
        ),
        _c(
          "Button",
          {
            attrs: { size: "large", type: "text" },
            on: { click: _vm.backPrev }
          },
          [_vm._v("返回上一页")]
        )
      ],
      1
    )
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "text-con" }, [
      _c("h4", [_vm._v("404")]),
      _c("h5", [_vm._v("Oh~~您的页面好像飞走了~")])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }