<template>
    <div class="error-page">
        <div class="content-con">
            <img :src="require('_a/images/error-page/error-404.svg')">
            <div class="text-con">
                <h4>404</h4>
                <h5>Oh~~您的页面好像飞走了~</h5>
            </div>
            <Button size="large" type="text" @click="backHome">返回首页</Button>
            <Button size="large" type="text" @click="backPrev">返回上一页</Button>
        </div>
    </div>
</template>

<script>

    export default {
        name: 'error_404',
        methods: {
            backHome () {
                this.$router.replace({
                    name: this.$store.state.auth.indexPage
                })
            },
            backPrev () {
                this.$router.go(-1)
            }
        },
    }
</script>
<style lang="less" scoped>
    .error-page {
        width: 100%;
        height: 100%;
        position: relative;
        background: #f8f8f9;

        .content-con {
            width: 700px;
            height: 600px;
            position: absolute;
            left: 50%;
            top: 50%;
            transform: translate(-50%, -60%);

            img {
                display: block;
                width: 100%;
                height: 100%;
            }

            .text-con {
                position: absolute;
                left: 0px;
                top: 0px;

                h4 {
                    position: absolute;
                    left: 0px;
                    top: 0px;
                    font-size: 80px;
                    font-weight: 700;
                    color: #348EED;
                }

                h5 {
                    position: absolute;
                    width: 700px;
                    left: 0px;
                    top: 100px;
                    font-size: 20px;
                    font-weight: 700;
                    color: #67647D;
                }
            }

            .back-btn-group {
                position: absolute;
                right: 0px;
                bottom: 20px;
            }
        }
    }

</style>
